
@media screen and (max-width: 480px) {
    .page-app-similar-app {
        margin: 0 -16px;
        border-radius: 0;
        border: 0px solid var(--card-border-color);
        border-width: 1px 0;
        margin-bottom: -1px; // collapses border between items
    }
}
