
.chart-tooltip {
    opacity: 0;
    top: 50%;
    position: absolute;
    z-index: 99999;
    font-size: 13px;
    color: var(--chart-tooltip-color);
    background: var(--chart-tooltip-background);
    border-radius: 12px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.35);
    // border: 1px solid var(--card-border-color);
    pointer-events: none;
    transition: .12s all ease;
    padding: 4px;

    &__header {
        padding: 4px;
        font-weight: 500;
    }

    &__line {
        margin-right: 10px;
    }

    &__footer {
        padding: 4px 0;
        border-spacing: 4px;
    }
}
