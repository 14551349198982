
.tx-status {
    display: flex;
    align-items: center;
    font-weight: 500;

    &--failed {
        color: var(--page-tx-status-error-color);
    }

    &--success {
        color: var(--page-tx-status-success-color);
    }

    &__icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
}

.tx-flow-schematics {
    display: flex;
    align-items: center;
    border: 1px solid var(--page-tx-flow-diagram-border-color);
    overflow: hidden;
    border-radius: 8px;
}

.tx-flow-schematics-step {
    display: flex;
    align-items: center;

    &__inner {
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
    }

    &__phase {
        font-size: 12px;
    }

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-color: var(--page-tx-flow-diagram-border-color);
        border-width: 0 1px 1px 0;
        border-style: solid;
        transform: rotate(-45deg);
        margin: 0 8px 0 -28px;
        user-select: none;
        pointer-events: none;
    }

    &:last-child::after {
        display: none;
    }
}
