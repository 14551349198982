
.tx-row-msg-action-badge {
    display: flex;
    align-items: center;
}

.tx-row-msg-action {
    font-size: 13px;
    display: flex;
    align-items: center;
    background: var(--card-row-separator);
    padding: 4px 8px 4px 6px;
    margin: -6px 8px;
    border-radius: 8px;
    cursor: help;
    &--single {
        margin-right: -1px;
    }
    &__icon {
        fill: currentColor;
        opacity: .6;
        margin-right: 6px;
        width: 16px;
        height: 16px;
    }
}

.swap-icon-arrow {
    transform: translateY(1px);
    width: 13px;
    height: 13px;
}

@media screen and (max-width: 600px) {
    .tx-row-msg-action {
        color: var(--body-muted-text-color);
        background: none;
        padding: 2px;
    }
}
