
.line-skeleton {
    padding: 0 20px;
}
.line-skeleton .skeleton {
    margin: 7px 0;
}
.line-chart-ear {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 8px 0 0;
    width: 165px;
    box-sizing: border-box;
}

.line-chart-ear-box {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 0 16px;

    header {
        margin-bottom: 6px;
        font-size: 14px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 22px;
        margin-bottom: 5px;
    }

    footer {
        font-size: 13px;
    }
}

.data-container {
    display: flex;
    height: 100%;
    flex-direction: row;
}

@media screen and (max-width: 599px) {
    .data-container {
        flex-direction: column;

        .chart-bar {
            max-height: calc(100% - 93px);
        }
    }

    .line-chart-ear {
        justify-content: space-between;
        flex-direction: row;
        width: unset;
        padding: 0 16px;
        margin: 0 0 20px 0;
    }

    .line-chart-ear-box {
        padding: 0;
    }
}
