
.cycle-step-status {
    color: var(--green-bright);
}

.validation-status-table {
    padding: 0 16px 12px 16px;
    border-spacing: 0;

    th {
        text-align: left;
        font-weight: normal;
        opacity: .4;
        padding: 3px 0;
    }

    td {
        padding: 3px 0;
    }
}

@media screen and (max-width: 600px) {
    .validation-status-table {
        table-layout: fixed;
        width: 100%;
        .mobile-hidden {
            display: none;
        }
    }
}
